export default  [
  {
      "key": "阿联酋",
      "value": "+971",
      "sx": "AE",
      "sxval": "+971      AE"
  },
  {
      "key": "阿尔巴尼亚",
      "value": "+355",
      "sx": "AL",
      "sxval": "+355      AL"
  },
  {
      "key": "安哥拉",
      "value": "+244",
      "sx": "AO",
      "sxval": "+244      AO"
  },
  {
      "key": "阿根廷",
      "value": "+54",
      "sx": "AR",
      "sxval": "+54      AR"
  },
  {
      "key": "奥地利",
      "value": "+43",
      "sx": "AT",
      "sxval": "+43      AT"
  },
  {
      "key": "澳大利亚",
      "value": "+61",
      "sx": "AU",
      "sxval": "+61      AU"
  },
  {
      "key": "阿塞拜疆",
      "value": "+994",
      "sx": "AZ",
      "sxval": "+994      AZ"
  },
  {
      "key": "比利时",
      "value": "+32",
      "sx": "BE",
      "sxval": "+32      BE"
  },
  {
      "key": "布基纳法索",
      "value": "+226",
      "sx": "BF",
      "sxval": "+226      BF"
  },
  {
      "key": "保加利亚",
      "value": "+359",
      "sx": "BG",
      "sxval": "+359      BG"
  },
  {
      "key": "巴林",
      "value": "+973",
      "sx": "BH",
      "sxval": "+973      BH"
  },
  {
      "key": "贝宁",
      "value": "+229",
      "sx": "BJ",
      "sxval": "+229      BJ"
  },
  {
      "key": "文莱",
      "value": "+673",
      "sx": "BN",
      "sxval": "+673      BN"
  },
  {
      "key": "玻利维亚",
      "value": "+591",
      "sx": "BO",
      "sxval": "+591      BO"
  },
  {
      "key": "巴西",
      "value": "+55",
      "sx": "BR",
      "sxval": "+55      BR"
  },
  {
      "key": "巴哈马",
      "value": "+1242",
      "sx": "BS",
      "sxval": "+1242      BS"
  },
  {
      "key": "白俄罗斯",
      "value": "+375",
      "sx": "BY",
      "sxval": "+375      BY"
  },
  {
      "key": "伯利兹",
      "value": "+501",
      "sx": "BZ",
      "sxval": "+501      BZ"
  },
  {
      "key": "加拿大",
      "value": "+1",
      "sx": "CA",
      "sxval": "+1      CA"
  },
  {
      "key": "瑞士",
      "value": "+41",
      "sx": "CH",
      "sxval": "+41      CH"
  },
  {
      "key": "智利",
      "value": "+56",
      "sx": "CL",
      "sxval": "+56      CL"
  },
  {
      "key": "喀麦隆",
      "value": "+237",
      "sx": "CM",
      "sxval": "+237      CM"
  },
  {
      "key": "中国大陆",
      "value": "+86",
      "sx": "CN",
      "sxval": "+86      CN"
  },
  {
      "key": "哥伦比亚",
      "value": "+57",
      "sx": "CO",
      "sxval": "+57      CO"
  },
  {
      "key": "哥斯达黎加",
      "value": "+506",
      "sx": "CR",
      "sxval": "+506      CR"
  },
  {
      "key": "佛得角",
      "value": "+238",
      "sx": "CV",
      "sxval": "+238      CV"
  },
  {
      "key": "塞浦路斯",
      "value": "+357",
      "sx": "CY",
      "sxval": "+357      CY"
  },
  {
      "key": "德国",
      "value": "+49",
      "sx": "DE",
      "sxval": "+49      DE"
  },
  {
      "key": "吉布提",
      "value": "+253",
      "sx": "DJ",
      "sxval": "+253      DJ"
  },
  {
      "key": "丹麦",
      "value": "+45",
      "sx": "DK",
      "sxval": "+45      DK"
  },
  {
      "key": "爱沙尼亚",
      "value": "+372",
      "sx": "EE",
      "sxval": "+372      EE"
  },
  {
      "key": "埃及",
      "value": "+20",
      "sx": "EG",
      "sxval": "+20      EG"
  },
  {
      "key": "西班牙",
      "value": "+34",
      "sx": "ES",
      "sxval": "+34      ES"
  },
  {
      "key": "芬兰",
      "value": "+358",
      "sx": "FI",
      "sxval": "+358      FI"
  },
  {
      "key": "法国",
      "value": "+33",
      "sx": "FR",
      "sxval": "+33      FR"
  },
  {
      "key": "加蓬",
      "value": "+241",
      "sx": "GA",
      "sxval": "+241      GA"
  },
  {
      "key": "英国",
      "value": "+44",
      "sx": "GB",
      "sxval": "+44      GB"
  },
  {
      "key": "格林纳达",
      "value": "+1473",
      "sx": "GD",
      "sxval": "+1473      GD"
  },
  {
      "key": "格鲁吉亚",
      "value": "+995",
      "sx": "GE",
      "sxval": "+995      GE"
  },
  {
      "key": "冈比亚",
      "value": "+220",
      "sx": "GM",
      "sxval": "+220      GM"
  },
  {
      "key": "几内亚",
      "value": "+224",
      "sx": "GN",
      "sxval": "+224      GN"
  },
  {
      "key": "赤道几内亚",
      "value": "+240",
      "sx": "GQ",
      "sxval": "+240      GQ"
  },
  {
      "key": "希腊",
      "value": "+30",
      "sx": "GR",
      "sxval": "+30      GR"
  },
  {
      "key": "危地马拉",
      "value": "+502",
      "sx": "GT",
      "sxval": "+502      GT"
  },
  {
      "key": "几内亚比绍",
      "value": "+245",
      "sx": "GW",
      "sxval": "+245      GW"
  },
  {
      "key": "圭亚那",
      "value": "+592",
      "sx": "GY",
      "sxval": "+592      GY"
  },
  {
      "key": "中国香港",
      "value": "+852",
      "sx": "HK",
      "sxval": "+852      HK"
  },
  {
      "key": "洪都拉斯",
      "value": "+504",
      "sx": "HN",
      "sxval": "+504      HN"
  },
  {
      "key": "克罗地亚",
      "value": "+385",
      "sx": "HR",
      "sxval": "+385      HR"
  },
  {
      "key": "匈牙利",
      "value": "+36",
      "sx": "HU",
      "sxval": "+36      HU"
  },
  {
      "key": "印度尼西亚",
      "value": "+62",
      "sx": "ID",
      "sxval": "+62      ID"
  },
  {
      "key": "爱尔兰",
      "value": "+353",
      "sx": "IE",
      "sxval": "+353      IE"
  },
  {
      "key": "以色列",
      "value": "+972",
      "sx": "IL",
      "sxval": "+972      IL"
  },
  {
      "key": "印度",
      "value": "+91",
      "sx": "IN",
      "sxval": "+91      IN"
  },
  {
      "key": "意大利",
      "value": "+39",
      "sx": "IT",
      "sxval": "+39      IT"
  },
  {
      "key": "牙买加",
      "value": "+1876",
      "sx": "JM",
      "sxval": "+1876      JM"
  },
  {
      "key": "约旦",
      "value": "+962",
      "sx": "JO",
      "sxval": "+962      JO"
  },
  {
      "key": "日本",
      "value": "+81",
      "sx": "JP",
      "sxval": "+81      JP"
  },
  {
      "key": "肯尼亚",
      "value": "+254",
      "sx": "KE",
      "sxval": "+254      KE"
  },
  {
      "key": "吉尔吉斯斯坦",
      "value": "+996",
      "sx": "KG",
      "sxval": "+996      KG"
  },
  {
      "key": "柬埔寨",
      "value": "+855",
      "sx": "KH",
      "sxval": "+855      KH"
  },
  {
      "key": "科摩罗",
      "value": "+269",
      "sx": "KM",
      "sxval": "+269      KM"
  },
  {
      "key": "韩国",
      "value": "+82",
      "sx": "KR",
      "sxval": "+82      KR"
  },
  {
      "key": "科威特",
      "value": "+965",
      "sx": "KW",
      "sxval": "+965      KW"
  },
  {
      "key": "开曼群岛",
      "value": "+1345",
      "sx": "KY",
      "sxval": "+1345      KY"
  },
  {
      "key": "斯里兰卡",
      "value": "+94",
      "sx": "LK",
      "sxval": "+94      LK"
  },
  {
      "key": "莱索托",
      "value": "+266",
      "sx": "LS",
      "sxval": "+266      LS"
  },
  {
      "key": "立陶宛",
      "value": "+370",
      "sx": "LT",
      "sxval": "+370      LT"
  },
  {
      "key": "卢森堡",
      "value": "+352",
      "sx": "LU",
      "sxval": "+352      LU"
  },
  {
      "key": "拉脱维亚",
      "value": "+371",
      "sx": "LV",
      "sxval": "+371      LV"
  },
  {
      "key": "摩洛哥",
      "value": "+212",
      "sx": "MA",
      "sxval": "+212      MA"
  },
  {
      "key": "摩尔多瓦",
      "value": "+373",
      "sx": "MD",
      "sxval": "+373      MD"
  },
  {
      "key": "马达加斯加",
      "value": "+261",
      "sx": "MG",
      "sxval": "+261      MG"
  },
  {
      "key": "马里",
      "value": "+223",
      "sx": "ML",
      "sxval": "+223      ML"
  },
  {
      "key": "蒙古",
      "value": "+976",
      "sx": "MN",
      "sxval": "+976      MN"
  },
  {
      "key": "中国澳门",
      "value": "+853",
      "sx": "MO",
      "sxval": "+853      MO"
  },
  {
      "key": "毛里塔尼亚",
      "value": "+222",
      "sx": "MR",
      "sxval": "+222      MR"
  },
  {
      "key": "毛里求斯",
      "value": "+230",
      "sx": "MU",
      "sxval": "+230      MU"
  },
  {
      "key": "马尔代夫",
      "value": "+960",
      "sx": "MV",
      "sxval": "+960      MV"
  },
  {
      "key": "马拉维",
      "value": "+265",
      "sx": "MW",
      "sxval": "+265      MW"
  },
  {
      "key": "墨西哥",
      "value": "+52",
      "sx": "MX",
      "sxval": "+52      MX"
  },
  {
      "key": "马来西亚",
      "value": "+60",
      "sx": "MY",
      "sxval": "+60      MY"
  },
  {
      "key": "莫桑比克",
      "value": "+258",
      "sx": "MZ",
      "sxval": "+258      MZ"
  },
  {
      "key": "纳米比亚",
      "value": "+264",
      "sx": "NA",
      "sxval": "+264      NA"
  },
  {
      "key": "尼日尔",
      "value": "+227",
      "sx": "NE",
      "sxval": "+227      NE"
  },
  {
      "key": "尼日利亚",
      "value": "+234",
      "sx": "NG",
      "sxval": "+234      NG"
  },
  {
      "key": "尼加拉瓜",
      "value": "+505",
      "sx": "NI",
      "sxval": "+505      NI"
  },
  {
      "key": "荷兰",
      "value": "+31",
      "sx": "NL",
      "sxval": "+31      NL"
  },
  {
      "key": "挪威",
      "value": "+47",
      "sx": "NO",
      "sxval": "+47      NO"
  },
  {
      "key": "新西兰",
      "value": "+64",
      "sx": "NZ",
      "sxval": "+64      NZ"
  },
  {
      "key": "阿曼",
      "value": "+968",
      "sx": "OM",
      "sxval": "+968      OM"
  },
  {
      "key": "巴拿马",
      "value": "+507",
      "sx": "PA",
      "sxval": "+507      PA"
  },
  {
      "key": "秘鲁",
      "value": "+51",
      "sx": "PE",
      "sxval": "+51      PE"
  },
  {
      "key": "巴布亚新几内亚",
      "value": "+675",
      "sx": "PG",
      "sxval": "+675      PG"
  },
  {
      "key": "菲律宾",
      "value": "+63",
      "sx": "PH",
      "sxval": "+63      PH"
  },
  {
      "key": "波兰",
      "value": "+48",
      "sx": "PL",
      "sxval": "+48      PL"
  },
  {
      "key": "巴勒斯坦",
      "value": "+970",
      "sx": "PS",
      "sxval": "+970      PS"
  },
  {
      "key": "葡萄牙",
      "value": "+351",
      "sx": "PT",
      "sxval": "+351      PT"
  },
  {
      "key": "卡塔尔",
      "value": "+974",
      "sx": "QA",
      "sxval": "+974      QA"
  },
  {
      "key": "罗马尼亚",
      "value": "+40",
      "sx": "RO",
      "sxval": "+40      RO"
  },
  {
      "key": "塞尔维亚",
      "value": "+381",
      "sx": "RS",
      "sxval": "+381      RS"
  },
  {
      "key": "俄罗斯",
      "value": "+7",
      "sx": "RU",
      "sxval": "+7      RU"
  },
  {
      "key": "卢旺达",
      "value": "+250",
      "sx": "RW",
      "sxval": "+250      RW"
  },
  {
      "key": "沙特阿拉伯",
      "value": "+966",
      "sx": "SA",
      "sxval": "+966      SA"
  },
  {
      "key": "塞舌尔",
      "value": "+248",
      "sx": "SC",
      "sxval": "+248      SC"
  },
  {
      "key": "瑞典",
      "value": "+46",
      "sx": "SE",
      "sxval": "+46      SE"
  },
  {
      "key": "新加坡",
      "value": "+65",
      "sx": "SG",
      "sxval": "+65      SG"
  },
  {
      "key": "斯洛文尼亚",
      "value": "+386",
      "sx": "SI",
      "sxval": "+386      SI"
  },
  {
      "key": "斯洛伐克",
      "value": "+421",
      "sx": "SK",
      "sxval": "+421      SK"
  },
  {
      "key": "塞拉利昂",
      "value": "+232",
      "sx": "SL",
      "sxval": "+232      SL"
  },
  {
      "key": "塞内加尔",
      "value": "+221",
      "sx": "SN",
      "sxval": "+221      SN"
  },
  {
      "key": "苏里南",
      "value": "+597",
      "sx": "SR",
      "sxval": "+597      SR"
  },
  {
      "key": "萨尔瓦多",
      "value": "+503",
      "sx": "SV",
      "sxval": "+503      SV"
  },
  {
      "key": "斯威士兰",
      "value": "+268",
      "sx": "SZ",
      "sxval": "+268      SZ"
  },
  {
      "key": "乍得",
      "value": "+235",
      "sx": "TD",
      "sxval": "+235      TD"
  },
  {
      "key": "多哥",
      "value": "+228",
      "sx": "TG",
      "sxval": "+228      TG"
  },
  {
      "key": "泰国",
      "value": "+66",
      "sx": "TH",
      "sxval": "+66      TH"
  },
  {
      "key": "塔吉克斯坦",
      "value": "+992",
      "sx": "TJ",
      "sxval": "+992      TJ"
  },
  {
      "key": "土库曼斯坦",
      "value": "+993",
      "sx": "TM",
      "sxval": "+993      TM"
  },
  {
      "key": "突尼斯",
      "value": "+216",
      "sx": "TN",
      "sxval": "+216      TN"
  },
  {
      "key": "土耳其",
      "value": "+90",
      "sx": "TR",
      "sxval": "+90      TR"
  },
  {
      "key": "特立尼达和多巴哥",
      "value": "+1868",
      "sx": "TT",
      "sxval": "+1868      TT"
  },
  {
      "key": "中国台湾",
      "value": "+886",
      "sx": "TW",
      "sxval": "+886      TW"
  },
  {
      "key": "坦桑尼亚",
      "value": "+255",
      "sx": "TZ",
      "sxval": "+255      TZ"
  },
  {
      "key": "乌克兰",
      "value": "+380",
      "sx": "UA",
      "sxval": "+380      UA"
  },
  {
      "key": "乌干达",
      "value": "+256",
      "sx": "UG",
      "sxval": "+256      UG"
  },
  {
      "key": "美国",
      "value": "+1",
      "sx": "US",
      "sxval": "+1      US"
  },
  {
      "key": "乌拉圭",
      "value": "+598",
      "sx": "UY",
      "sxval": "+598      UY"
  },
  {
      "key": "乌兹别克斯坦",
      "value": "+998",
      "sx": "UZ",
      "sxval": "+998      UZ"
  },
  {
      "key": "委内瑞拉",
      "value": "+58",
      "sx": "VE",
      "sxval": "+58      VE"
  },
  {
      "key": "英属维尔京群岛",
      "value": "+1284",
      "sx": "VG",
      "sxval": "+1284      VG"
  },
  {
      "key": "越南",
      "value": "+84",
      "sx": "VN",
      "sxval": "+84      VN"
  },
  {
      "key": "也门",
      "value": "+967",
      "sx": "YE",
      "sxval": "+967      YE"
  },
  {
      "key": "南非",
      "value": "+27",
      "sx": "ZA",
      "sxval": "+27      ZA"
  },
  {
      "key": "赞比亚",
      "value": "+260",
      "sx": "ZM",
      "sxval": "+260      ZM"
  },
  {
      "key": "津巴布韦",
      "value": "+263",
      "sx": "ZW",
      "sxval": "+263      ZW"
  }
]